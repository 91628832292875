import { SvgIconProps } from '@/types/helpers'

export default function Timer(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="#8B8B8B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 21.7125C7.37646 21.7125 3.5332 17.8693 3.5332 13.2457C3.5332 8.92102 6.87842 5.27698 11.1035 4.82873V4.01526C11.1035 3.51721 11.5103 3.10217 11.9917 3.10217C12.4897 3.10217 12.8799 3.51721 12.8799 4.01526V4.82873C14.6064 5.01135 16.1919 5.72522 17.4536 6.80432L18.0845 6.19006C18.2837 5.99914 18.4829 5.91614 18.7651 5.91614C19.2466 5.91614 19.6201 6.28967 19.6201 6.75451C19.6201 6.97864 19.5122 7.23596 19.3213 7.42688L18.6738 8.08264C19.7944 9.51868 20.4668 11.3116 20.4668 13.2457C20.4668 17.8693 16.6318 21.7125 12 21.7125ZM12 20.3014C15.918 20.3014 19.064 17.1637 19.0557 13.2457C19.0474 9.32776 15.9097 6.19006 11.9917 6.19006C8.07373 6.19006 4.95264 9.32776 4.95264 13.2457C4.95264 17.1637 8.08203 20.3014 12 20.3014ZM12 14.5905C11.2529 14.5905 10.6636 13.9928 10.6636 13.254C10.6636 12.7311 10.9624 12.2828 11.419 12.0421V7.92493C11.419 7.60119 11.668 7.35217 11.9917 7.35217C12.3154 7.35217 12.5728 7.60119 12.5728 7.92493V12.0504C13.0376 12.2745 13.3364 12.7311 13.3364 13.254C13.3364 13.9928 12.7388 14.5905 12 14.5905Z"
        fill="currentFill"
      />
    </svg>
  )
}
