'use client'

import Timer from '@/app/_components/Icon/Timer'
import { Button } from '@/app/_primitives/Button'
import Link from 'next/link'

export default function ReadButton() {
  return (
    <Button
      asChild
      color="butterScotch"
      className="w-166 items-center justify-center"
      type="button">
      <Link href="/blog/2">
        <Timer fill="#000" />5 min read
      </Link>
    </Button>
  )
}
