'use client'

import { Button } from '@/app/_primitives/Button'
import { useI18n } from '@/locales/client'
import Link from 'next/link'

export default function ViewButton() {
  const t = useI18n()
  const btnTxt = t('common.viewBlogBtnLabel')
  return (
    <Button
      asChild
      color="butterScotch"
      className="w-166 items-center justify-center"
      type="button">
      <Link href="/blog/1">{btnTxt}</Link>
    </Button>
  )
}
