import { ReactNode } from 'react'

function Logo({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) {
  return (
    <div className={className}>
      <div className="absolute font-retro-chimps text-white">{children}</div>
      <div className="font-retro-chimps-extrude text-cherry">{children}</div>
    </div>
  )
}

const Typography = {
  Logo,
}

export default Typography
