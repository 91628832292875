'use client'

import Timer from '@/app/_components/Icon/Timer'
import { LogoImage } from '@/app/_components/LogoImage'
import I18nTranslator from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import Typography from '@/app/_components/Typography'
import Image from 'next/image'
import Link from 'next/link'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import ReadButton from './_component/ReadButton'
import ViewButton from './_component/ViewButton'
import img1 from './img_1.png'
import img2 from './img_2.png'
import img3 from './img_3.png'
import img4 from './img_4.png'

export function Card({
  children,
  className,
  src,
}: {
  children?: React.ReactNode
  className?: string
  src: ComponentProps<typeof Image>['src']
}) {
  return (
    <div className={className}>
      <Image
        src={src}
        fill
        sizes="100vw"
        alt="img"
        className="object-cover"
        style={{}}
      />
      <div
        className="absolute inset-0"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)`,
        }}
      />
      <div className={twMerge('absolute inset-0')}>{children}</div>
    </div>
  )
}

export default function Guide() {
  // TODO call getMainPageBlogs
  return (
    <div className="flex w-full flex-col gap-75">
      <div className="flex flex-col gap-11">
        <span className="c-b1 text-center text-white">
          {<I18nTranslator text={'common.guideTitle1'} />}
        </span>
        <span className="b-h4 text-center text-white lg:b-h3">
          {<I18nTranslator text={'common.guideTitle2'} />}
        </span>
      </div>

      <div className="flex flex-col gap-20 xl:flex-row">
        <Card
          className="relative h-525 w-full shrink-0 overflow-hidden rounded-20 transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl lg:h-758 xl:w-624"
          src={img1}>
          <div className="flex size-full flex-col p-60 max-lg:justify-between lg:gap-203">
            <div className="flex items-center gap-21">
              <LogoImage width={60} height={60} />
              <Typography.Logo className="text-38">inabooth</Typography.Logo>
            </div>
            <div className="flex flex-col gap-24">
              <span className="b-h3 whitespace-pre-line break-keep text-white lg:b-h2">
                캐릭터 라이선싱 <br className="hidden sm:block" /> 초보자를 위한
                가이드
              </span>
              <ViewButton />
            </div>
          </div>
        </Card>

        <div className="flex w-full flex-col gap-20">
          <Card
            className="relative h-371 w-full overflow-hidden rounded-20 transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl lg:h-437"
            src={img2}>
            <div className="flex size-full flex-col justify-between p-60">
              <div className="flex flex-col gap-7">
                <div className="flex items-center gap-11">
                  <span className="flex h-20 items-center rounded-5 bg-french-bleu px-[10.5px] font-noto-sans-kr text-10 font-bold text-white">
                    전략
                  </span>
                  <span className="c-b3 text-white">12월 27일</span>
                </div>
                <span className="b-h4 text-white lg:b-h3 max-sm:text-28 sm:whitespace-pre-line sm:break-keep">
                  캐릭터 IP 사업화를 위한 <br className="hidden sm:block" />
                  혁신적인 올인원 솔루션 <br className="hidden sm:block" />
                  &apos;이너부스&apos;
                </span>
              </div>
              <ReadButton />
            </div>
          </Card>
          <div className="flex flex-col gap-20 lg:flex-row">
            <Link href="/blog/3" className="contents">
              <Card
                className="relative h-224 w-full overflow-hidden rounded-20 transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl lg:h-297"
                src={img3}>
                <div className="flex size-full flex-col gap-12 p-32">
                  <div className="flex flex-col gap-12">
                    <div className="flex items-center gap-10">
                      <span className="flex h-20 items-center rounded-5 bg-french-bleu px-[10.5px] font-noto-sans-kr text-10 font-bold text-white">
                        전략
                      </span>
                      <span className="c-b3 text-white">10월 13일</span>
                    </div>
                    <span className="b-h4 text-white max-sm:b-h5 sm:whitespace-pre-line sm:break-keep">
                      글로벌 시장을 향한 <br className="hidden sm:block" />
                      이너부스의 새로운 도약
                    </span>
                  </div>
                  <div className="c-b3 flex items-center gap-9 text-white">
                    <Timer fill="#FFF" />
                    <span>5 min read</span>
                  </div>
                </div>
              </Card>
            </Link>
            <div className="max-xl:contents xl:hidden 2xl:contents">
              <Link href="/blog/4" className="contents">
                <Card
                  className="relative h-224 w-full overflow-hidden rounded-20 transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl lg:h-297"
                  src={img4}>
                  <div className="flex size-full flex-col gap-12 p-32">
                    <div className="flex flex-col gap-12">
                      <div className="flex items-center gap-10">
                        <span className="flex h-20 items-center rounded-5 bg-french-bleu px-[10.5px] font-noto-sans-kr text-10 font-bold text-white">
                          전략
                        </span>
                        <span className="c-b3 text-white">7월 21일</span>
                      </div>
                      <span className="b-h4 whitespace-pre-line break-keep text-white max-sm:b-h5">
                        캐릭터 상품 개발과 생산 | 이너팩토리
                      </span>
                    </div>

                    <div className="c-b3 flex items-center gap-9 text-white">
                      <Timer fill="#FFF" />
                      <span>10 min read</span>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
